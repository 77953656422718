<template>
  <v-row no-gutters align="center" justify="space-between">
    <v-col cols="12" :class="$vuetify.breakpoint.smAndDown ? 'mx-auto' : ''">
      <v-card class="mb-1 pt-2 pb-1">
        <ns-data-table
          :headers="transactionsHeader"
          class="my-table supply"
          dense
          :request-service="getRequestService"
          :search-params="params"
        >
          <template v-slot:[`item.transaction_id`]="{ item }">
            <span class="copyTxt" @click="copyToClipboard(item.transaction_id)">
              {{ item.transaction_id }}
            </span>
          </template>
          <template v-slot:[`item.amount`]="{ item }">
            <v-tooltip bottom v-if="item.transaction_type == 'OUT'">
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on" class="font-weight-medium">
                  -{{ item.amount }}{{ item.currency }}
                </span>
              </template>
              {{ $t("table.price") }}: {{ item.price }}<br />
              {{ $t("table.amount") }}: {{ item.quantity }}
            </v-tooltip>
            <span v-else class="font-weight-medium" style="color: #4FAE3B !important">
              {{ item.amount }} {{ item.currency }}
            </span>
          </template>
          <template v-slot:[`item.service`]="{ item }">
            {{
              item.service == "USER_CRM_DAYS"
                ? $t("cabinet.crm_days")
                : item.service == "USER_DELIVERY_AMOUNT"
                ? $t("cabinet.deliveries")
                : "--"
            }}
          </template>
          <template v-slot:[`item.description`]="{ item }">
            {{ item.description || "--" }}
          </template>
        </ns-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import SizeUi from "@/mixins/SizeUi";
import user from "@/mixins/user";
import supplyService from "@/services/request/supply/supplyService";
import notifications from "@/mixins/notifications";

export default {
  data: () => ({
    params: {}
  }),
  mixins: [SizeUi, user, notifications],
  methods: {
    async copyToClipboard(e) {
      try {
        await navigator.clipboard.writeText(e);
        this.setSuccessNotification(this.$t("profile.copied_to_clipboard"));
      } catch (e) {
        console.log(e);
      }
    }
  },
  computed: {
    getRequestService() {
      return supplyService.getTransactions;
    },
    transactionsHeader() {
      const result = [
        { text: "#", value: "order", sortable: false },
        {
          text: this.$t("supply.transactions.transaction_id"),
          value: "transaction_id",
          align: "left",
          sortable: false
        },
        {
          text: `${this.$t("form.name")}`,
          value: "sender_first_name",
          align: "left",
          sortable: false
        },
        {
          text: `${this.$t("form.surname")}`,
          value: "sender_last_name",
          align: "left",
          sortable: false
        },
        {
          text: `${this.$t("table.amount")}`,
          value: "amount",
          align: "center",
          sortable: false
        },
        {
          text: `${this.$t("table.type")}`,
          value: "service",
          align: "left",
          sortable: false
        },
        // {
        //   text: `${this.$t("supply.transactions.currency")}`,
        //   value: "currency",
        //   align: "center",
        //   sortable: false
        // },
        // {
        //   text: `${this.$t("table.description")}`,
        //   value: "service",
        //   align: "left",
        //   sortable: false
        // },
        {
          text: `${this.$t("table.description")}`,
          value: "description",
          align: "left",
          sortable: false
        },
        {
          text: `${this.$t("form.time_created")}`,
          value: "time_created",
          align: "left",
          sortable: false
        }
      ];
      return result;
    }
  }
};
</script>
